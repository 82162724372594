import React, { useEffect, useState, useCallback } from 'react';
import { Provider } from 'beautiful-react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import jquery from 'jquery';
import Cookies from 'js-cookie';
import { enableBodyScroll } from 'body-scroll-lock';
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

import configureStore from '../config/configureStore';
import history from "../config/history";
import { BASE_URL } from "../constants/EnvVariables";
import { getDataFromServer } from '../helpers/getDataFromServer';

import SetColorScheme from "./SetColorScheme";
import AppendToHead from "./AppendToHead";

import '../components/vendor/bvi/dist/css/bvi.css';
import bvi from '../components/vendor/bvi/dist/js/bvi';

const BasicRouter = React.lazy(() => import('./BasicRouter'));

window.$ = jquery;
window.editBlocked = 0;

const renderLoader = () => <p>Загрузка</p>;

const { storeRedux, persistor } = configureStore();

const initializeBvi = () => {
    new bvi.Bvi({
        target: '.site-menu__visually-impaired',
        fontSize: 24,
        theme: 'black',
        speech: false
    });

    enableBodyScroll(window.$('body'));
};

const App = () => {
    const [location, setLocation] = useState(0);
    const [editBlocked, setEditBlocked] = useState(0);

    const routerRef = React.createRef();
    const providerRef = React.createRef();

    // Для проверки, что сайт не редактируется глобальным админом
    const callCheckBlockedSite = useCallback(() => {
        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: `${BASE_URL}api/check-blocked-site`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('site_builder_token')}`
            },
            success: (data) => {
                window.editBlocked = data.editBlocked;
                setEditBlocked(data.editBlocked);
            },
            error: () => {}
        });
    }, []);

    useEffect(() => {
        const intervalGetEditBlocked = setInterval(callCheckBlockedSite, 30000);
        callCheckBlockedSite();

        return () => clearInterval(intervalGetEditBlocked);
    }, [callCheckBlockedSite]);

    useEffect(() => {
        setChonkyDefaults({ iconComponent: ChonkyIconFA });

        if (window.location.pathname !== "/auth") {
            if (!Cookies.get('site_builder_token') && process.env.REACT_APP_MODE === 'production') {
                window.location.pathname = "/auth";
            } else {
                getDataFromServer(storeRedux);

                if (process.env.REACT_APP_BUILD_MODE === 'production' && window.location.pathname === "/") {
                    window.localStorage.setItem('gosweb-disclaimer-shown', true);
                }
            }
        }
    }, []);

    useEffect(() => {
        initializeBvi();
    }, []);

    const setCurrentLocation = (path) => {
        setLocation(path);
    };

    return (
        <div>
            {editBlocked && process.env.REACT_APP_BUILD_MODE !== 'production' && (
                <div className='constructor__edit-blocked-message'>
                    Сайт редактируется глобальным администратором, Ваши изменения не будут сохранены
                </div>
            )}

            <div className="App" data-controller="modal-window page-modal scrollbar" data-action="keyup@document->modal-window#onKeypress">
                <Provider store={storeRedux} ref={providerRef}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Router history={history}>
                            <React.Suspense fallback={renderLoader()}>
                                <AppendToHead />
                                <SetColorScheme />
                                <BasicRouter ref={routerRef} store={storeRedux} setMainLocation={setCurrentLocation} currentLocation={location} />
                            </React.Suspense>
                        </Router>
                    </PersistGate>
                </Provider>
            </div>

            <div className="disclaimer">
                Сайт использует файлы cookies и сервис сбора технических данных его посетителей.
                <br />Продолжая использовать данный ресурс, вы автоматически соглашаетесь с использованием данных технологий.
                <div className="disclaimer__close">Закрыть</div>
            </div>
        </div>
    );
};

export default App;